<template>
  <v-row justify="center" class="space px-16">
    <v-col cols="12" xs="12" sm="6" md="4" v-for="(product, i) in products" :key="i" >
      <v-card class="mx-auto rounded-xl" max-width="300" color="" flat outlined>
        <div align="center" justify="center">
          <v-img
            max-height="300"
            max-width="300"
            :src="product.img"
            style="border-radius: 23px"
          ></v-img>
        </div>

        <v-card-title style="word-break: break-word" >{{ product.title }}</v-card-title>
        <v-card-title class="grey--text text-grey-darken-1 caption mt-n6">{{
          product.subtitle
        }}</v-card-title>
        <v-card-title class="mt-n4">{{ product.price }}</v-card-title>
        <v-card-actions class="mx-2 mt-n4">          
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
      bpm: 1,
    products: [
      { img: "arm1.jpg", title: "Lint neon wave", subtitle: "per meter", price: "€1" },
      { img: "arm2.jpg", title: "Lint neon love life", subtitle: "per meter", price: "€1" },
      { img: "arm3.jpg", title: "Lint neon love", subtitle: "per meter", price: "€1" },
      { img: "arm4.jpg", title: "Lint neon good vibes", subtitle: "per meter", price: "€1" },
      { img: "arm5.jpg", title: "Lint neon bloemetjes", subtitle: "per meter", price: "€1" },
      
      { img: "arm6.jpg", title: "Armbandje Berghem", subtitle: "", price: "€1,75" },
      { img: "arm7.jpg", title: "Armbandje Borculo", subtitle: "", price: "€1,75" },
      { img: "arm8.jpg", title: "Armbandje Wageningen", subtitle: "", price: "€3,00" },
      { img: "arm9.jpg", title: "Armbandje Kaatsheuvel", subtitle: "", price: "€1,75" },
      { img: "arm10.jpg", title: "Armbandje Eindhoven", subtitle: "", price: "€1,75" },
      { img: "arm11.jpg", title: "Enkelbandje Scheveningen", subtitle: "", price: "€2,00" },
      { img: "arm14.jpg", title: "Armbandje Rotterdam", subtitle: "", price: "€1,75" },
      { img: "arm15.jpg", title: "Armbandje Nijmegen", subtitle: "", price: "€1,75" },


      { img: "arm17.jpg", title: "Ketting Oss", subtitle: "", price: "€3,65" },
      { img: "arm18.jpg", title: "Armbandje Groningen", subtitle: "", price: "€2,25" },
      { img: "arm19.jpg", title: "Armbandje Utrecht", subtitle: "", price: "€1,75" },
      { img: "arm21.jpg", title: "Armbandje Tilburg", subtitle: "", price: "€1,75" },
      { img: "arm23.jpg", title: "Armbandje kind Den Bosch", subtitle: "", price: "€1,75" },
      { img: "arm24.jpg", title: "Armbandje Den Haag", subtitle: "", price: "€1,75" },
      { img: "arm25.jpg", title: "Armbandje Lelystad", subtitle: "", price: "€1,75" },
      { img: "arm26.jpg", title: "Enkelbandje Lochem", subtitle: "", price: "€2,00" },
      { img: "arm27.jpg", title: "Sieraden set Amsterdam", subtitle: "", price: "€5,00" },
      { img: "arm28.jpg", title: "Armbandje Veghel", subtitle: "", price: "€1,75" },

      { img: "arm29.jpg", title: "4mm donkerblauw", subtitle: "7g", price: "€0,50" },
      { img: "arm30.jpg", title: "4mm metallic brons", subtitle: "7g", price: "€0,50" },
      { img: "arm31.jpg", title: "4mm metallic groen", subtitle: "7g", price: "€0,50" },

      { img: "arm33.jpg", title: "Acryl hartjes smiley", subtitle: "10 stuks", price: "€1,20" },
      { img: "arm34.jpg", title: "Acryl bloem smileys", subtitle: "10 stuks", price: "€1,20" },
      { img: "arm35.jpg", title: "Acryl mix klein", subtitle: "10 stuks", price: "€1,00" },
    ],
  }),
   methods: {
     
    },
};
</script>

<style>
</style>