<template>
    <v-app-bar app color="light-pink" flat>
      <v-badge color="transparent" dot>
        <v-img src="logo.png" contain width="80"></v-img>
      </v-badge>
  
      <v-toolbar-title class="ml-4">
        <span class="paars">De Creatieve </span><strong>Kraal</strong>
      </v-toolbar-title>
    </v-app-bar>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style>
  .v-toolbar__title {
    font-size: 1rem !important;
  }

  .paars{
    color: #A03EFC;
  }
  </style>