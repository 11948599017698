<template>
  <v-footer dark padless>
    <v-card flat tile class="black white--text py-12 px-5" width="100%">
      <v-row align="center">
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-toolbar color="transparent" flat>
            <v-badge color="#transparent" dot>
              <v-img src="logo.png" contain width="80"></v-img>
            </v-badge>

            <v-toolbar-title class="ml-8">
              <span class="paars">De Creatieve </span><strong>Kraal</strong>
            </v-toolbar-title>
          </v-toolbar>

        </v-col>

        <v-col cols="12" xs="12" sm="6" md="2">
          <v-card-text class="white--text pt-0">
            <h3>Hoe werkt het?</h3>
          </v-card-text>
          <v-card-text class="grey--text"> Stuur mij een berichtje via Instagram of TikTok met wat je wilt bestellen. Dan spreken we daar af hoe en waar je bestelling bezorgd moet worden. </v-card-text>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="4">
          <v-card-text class="white--text pt-0">
            <h3>Contact</h3>
          </v-card-text>
          <v-card-text class="mt-n4"> <a class="grey--text" style="text-decoration: none;" href="https://www.tiktok.com/@decreatievekraal?_t=8YRda4j9muj&_r=1" target="_blank">TikTok</a> </v-card-text>
          <v-card-text class=" mt-n4"><a class="grey--text" style="text-decoration: none;" href="https://www.instagram.com/decreatievekraal/" target="_blank">Instagram</a></v-card-text>
        </v-col>

      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    
  }),
};
</script>

<style>

.paars{
    color: #A03EFC;
  }
</style>